
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/people/teacher/List.vue";
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";

interface ComponentData {
  teacherIdModal: number | boolean;
}
export default defineComponent({
  name: "teacher-index",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
  },
  data(): ComponentData {
    return {
      teacherIdModal: false,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Giảng viên", ["Đào tạo"]);
      MenuComponent.reinitialization();
    });
    return {};
  },
});
